import React from 'react';

import style from '../css/components/Loading.module.scss';

const WindMillLoading = () => {
    return (
        <div className={style.holder}>
            <div className={style.fontLoader}>__</div>
            <h1 style={{fontFamily: '"Open Sans", san-serif', fontSize: 25}}> Loading </h1>
            {/*<span className={style.windmill2}>*/}
            {/*    <span className={style.pillar}/>*/}
            {/*    <span className={style.axis}>*/}
            {/*        <span className={style.swing}/>*/}
            {/*        <span className={style.swing2}/>*/}
            {/*        <span className={style.swing3}/>*/}
            {/*    </span>*/}
            {/*</span>*/}

            {/*<div className={style.container}>*/}
            {/*    <div className={style.center}/>*/}
            {/*    <div className={style.con}>*/}
            {/*        <div className={style.ItemFirst}/>*/}
            {/*        <div className={style.ItemSecond}/>*/}
            {/*        <div className={style.ItemThree}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default WindMillLoading;
