import React from 'react';

// export class SomeIcon extends React.Component<{ icon: 'SolarIcon' | 'GridIcon' | 'LoadIcon' }, {}> {
//     render() {
//         let {icon} = this.props;
//         if (icon === 'SolarIcon') {
//             return <SolarIcon/>;
//         } else if (icon === 'GridIcon') {
//             return <GridIcon/>;
//         } else if (icon === 'LoadIcon') {
//             return <LoadIcon/>;
//         }
//     }
// }

export class SolarIcon extends React.Component<any, any> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 114" className={"flow-icon"}>
                <g>
                    <path
                        d="M128.8 54.9v5.6c0 .4-.3.8-.6 1.1a8789.1 8789.1 0 01-30.4 31.9c-.4.4-1.2.6-1.8.6H14.6v-5.3h78c1.1 0 1.9-.3 2.7-1L127.5 56l1.3-1.2zm-91.6 59v-2.5H53V96.8H69v14.5h16v2.5H37.2zm-4-90c0 5-4.3 9.2-9.3 9.2a9.4 9.4 0 01-9.3-9.5c0-4.9 4.3-9 9.3-9 5 0 9.3 4.2 9.3 9.2zm5.5 38l1.6-1.2 8.4-8.3c.4-.3 1-.6 1.4-.6h17.2l-1 1-8.8 8.8c-.3.3-.9.7-1.4.7H39l-.3-.4z"/>
                    <path
                        d="M127.6 51.8l-9.7 10a2 2 0 01-1.3.5H101l.6-.9 9-9c.4-.3 1-.6 1.4-.6H127.6zM42.3 76.9c-2.7 2.6-5.2 5-7.5 7.5a5.3 5.3 0 01-4.5 1.8H14.5l-.1-.4 2.7-2.5 6-5.8c.3-.3.8-.6 1.2-.6h18zm12-11.8l-3.4 3.2-6 5.9H26.6l.6-.7 8-7.9c.4-.3 1-.6 1.4-.6h17.6zm6.6-3l4-4.1 5.7-5.6c.3-.3.9-.6 1.3-.6h15.4l-.7.8-9 9c-.4.4-1 .7-1.4.7H61.1l-.2-.2zm19.8 0l10.2-10c.2-.2.6-.3 1-.3h15l.3.1-.7.8-9 9c-.3.3-.9.6-1.4.6H81.4l-.7-.1zm33.5 2.9l-8.6 9-1 .3H88.8l.7-.9 7.8-7.7c.3-.3.8-.6 1.2-.6l15.7-.1zM77 85.9l4-4 4.4-4.4c.3-.3.9-.6 1.3-.6h15.2l.4.1-3.8 3.9-4.7 4.6c-.4.3-.9.6-1.3.6H77L77 86zM48.7 74.2l9-8.9.7-.3h15.7L73 66l-7.5 7.4c-.4.4-1 .7-1.6.7H48.7zM94.2 65l-9 9-.8.2H68.9l.6-.9 7.8-7.7c.3-.3.8-.6 1.2-.6h15.7zm-32 11.9l-9 8.9c-.1.2-.5.3-.8.3H36.9l.6-.7 8-7.9c.2-.3.6-.6 1-.6h15.7zm20 .1l-8.8 8.7c-.3.3-.8.4-1.2.4H57.4 57l1.7-1.8 6.8-6.7c.3-.3.9-.7 1.4-.7h14.7l.6.1zm-66-62.4l-1.6 1.6-8.3-8.3 1.5-1.6 8.3 8.3zm23.4 26.8l-8.1-8.2 1.8-1.6 8.2 8.2-1.9 1.6zm.5-35l1.4 1.4-8.4 8.5-1.3-1.7 8.3-8.3zM7.8 41.5L6.2 40l8.5-8.4 1.4 1.7-8.3 8.3zM22.6.1h2.5v11.8h-2.5V0zM.1 22.6h11.7v2.5H.1v-2.5zm35.8 2.5v-2.5h11.8v2.5H35.9zM25 47.7h-2.4V35.9H25v11.8z"/>
                </g>
            </svg>
        )
    }
}

export class GridIcon extends React.Component<any, any> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 95" className={"flow-icon"}>
                <path
                    d="M3.2 14.1V20c-.1.6-.9 1.2-1.3 1.8-.5-.6-1.5-1.2-1.6-1.9-.1-2.2-.2-4.5 0-6.7.1-.8 1-2 1.7-2.3L26.3.4C27 0 27.8 0 28.5 0h19c1 0 2 .3 3 .6l23.3 10c1.6.6 2.2 1.4 2.2 3l-.1 5.7c-.2.8-.9 1.6-1.4 2.4-.4-.7-1.2-1.4-1.3-2.2-.2-1.8 0-3.5 0-5.5h-23c0 2.5-.2 5 0 7.3.1.8 1.2 1.6 2 2 7 3 14.1 6.2 21.2 9 2 1 2.7 2 2.6 4l-.1 5.7c-.1.6-1 1.2-1.5 1.7-.4-.6-1.1-1.1-1.2-1.8-.2-1.9 0-3.7 0-5.8H50.8l2.4 8 15 47.5c.3 1 0 2.2-.1 3.3-1-.4-2.1-.6-2.9-1.2L42.6 72.6c-.7-.6-.6-2-1-3 1 .3 2.2.3 3 1l16.9 15.7 3 2.6-11-34.7L39 67.7 11 93.6c-.7.7-2 1-3 1.4 0-1.1-.4-2.3 0-3.4l16.4-52.4 1-3.2H3.1v5.7c-.1.8-1 1.4-1.5 2-.4-.6-1.3-1.3-1.4-2-.7-7.8-.7-7.8 6.4-11 5.6-2.4 11.2-4.8 16.9-7 2-.9 2.8-2 2.5-4V14H3zM38 64.5l13.5-12.6c.4-.5.6-1.5.4-2.2-1.1-4-2.4-8-3.8-12-.3-.8-1.5-1.7-2.3-1.7a237 237 0 00-15.5 0c-.8 0-2 .9-2.3 1.7-1.5 3.8-2.7 7.7-3.8 11.7-.3.7-.1 2 .3 2.4 4.4 4.3 8.9 8.4 13.5 12.7zm-2.1 2L22.7 54.3C19.1 65.9 15.7 77 12.1 88l.5.3L36 66.5zM29 33h18v-8H29v8zm0-22.1h18V3H29V11zm0 10.9h18.1V14h-18v7.7zM10 10.5l.2.4h15.6V3.7l-15.7 6.8zm56 .4l.2-.4-16-6.8V11h15.9zM50.3 33H66v-.4l-15.8-6.8V33zm-40-.5v.7h9.5c6.6 0 6.6 0 6.3-6.6 0-.2-.3-.3-.5-.6l-15.3 6.5z"/>
            </svg>
        )
    }
}

export class LoadIcon extends React.Component<any, any> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 85" className={"flow-icon"}>
                <path
                    d="M73.2 28V14.8H87V9H73V.2l-.9-.2L54 .1a21.3 21.3 0 00-20.5 16c-.3 1.1-.8 1.2-1.7 1.2H19.4c-6.5 0-11.8 2.6-15.6 7.9C-.7 31.4-.5 38.5.9 45.4c1.8 8.8 9.5 14.3 18.7 14.3h36.1a8 8 0 018 9.7c-.8 3.8-4 6.3-8 6.3H16v8.5c13.8 0 27.5.4 41.2-.1 11.9-.5 19-13.8 13.2-24.2C67 53.7 61.6 51 54.7 51H18.9c-4.7 0-8.7-3-9.7-7.5-.5-2.4-.3-5-.4-7.4 0-5.9 4.5-10.3 10.3-10.4h12.4c1.2 0 1.7.2 2 1.5 2.7 9.4 11 15.7 20.8 15.8h17.4l1.5-.2v-9H87V28H73.2zm-21.9 8.4h-.2L54.3 24h-6.8l.5-1 6.8-13c.4-.7 1-1 1.8-1h6l-5 9.5h8L51.2 36.4z"/>
            </svg>
        )
    }
}
