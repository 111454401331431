import React from 'react';

export default class Footer extends React.Component<{footerText?: string, url?: string, urlText?: string}> {
    render() {
        return (
            <div style={{
                paddingTop: 14,
                textAlign: "center",
                color: "rgba(0,0,0,0.5)",
                fontSize: 15,
                fontWeight: 600
            }}>
                {this.props.footerText} <a href={this.props.url} style={{color: "#65427e"}}>{this.props.urlText}</a>
            </div>
        )
    }
}
