import React from 'react';

type MyState = {
    hours: number
    minutes: number
};

export default class TitleHeader extends React.Component<{ name: string }, MyState> {
    state: MyState = {
        hours: 0,
        minutes: 0
    };

    timerID: NodeJS.Timer | null = null;

    componentDidMount() {
        this.timerID = setInterval(() => this.tick(), 5000);

        this.tick()
    }

    componentWillUnmount() {
        if (this.timerID !== null) {
            clearInterval(this.timerID);
            this.timerID = null;
        }
    }

    tick() {
        let date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();

        if (this.state.hours === hours && this.state.minutes === minutes) {
            return;
        }

        this.setState(() => ({
            hours,
            minutes
        }));
    }

    renderTime() {
        return (
            <div className="time">{this.state.hours.toString().padStart(2, '0')}:{this.state.minutes.toString().padStart(2, '0')}</div>
        )
    }

    renderLogo() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="234" height="48" viewBox="0 0 234 48">
                <g fillRule="evenodd">
                    <g>
                        <g>
                            <path fill="#CE1126"
                                d="M221.675 29.043h3.422l1.205-3.61h-16.175c0 5.651.155 7.457 5.677 7.457 3.5.039 5.871.078 5.871-3.847m-11.51-7.574h11.51c0-5.377-.777-5.81-5.832-5.81-5.328 0-5.678 1.178-5.678 5.81M231.435 0h-58.054v37.442h-6.183l.233-3.964h-.194c-1.555 3.454-4.433 4.24-8.05 4.24-9.565 0-9.876-6.163-9.876-14.209 0-7.692 1.011-12.637 9.877-12.637 3.15 0 6.416.588 7.738 3.807h.233V0h-32.274s-3.577 0-4.744 3.415l-2.877 8.438c1.672-.667 3.85-.981 6.727-.981 10.615 0 12.015 3.14 12.015 14.6h-17.692c0 5.651.155 7.457 5.677 7.457 3.422 0 5.833.04 5.833-3.846h6.182c0 8.242-4.666 8.634-12.015 8.634-8.982 0-11.432-2.669-11.821-10.832l-5.988 17.7S115.015 48 118.592 48h62.76c-2.41-1.138-3.85-3.415-3.616-7.889h5.794c0 3.611 1.322 4.16 4.821 4.16 5.25 0 6.377-1.098 6.377-5.612v-4.984h-.155c-1.633 3.453-4.9 4.042-8.05 4.042-8.826 0-9.876-4.945-9.876-12.638 0-8.045.311-14.207 9.877-14.207 3.616 0 6.493.785 8.049 4.238h.194l-.233-3.964h6.183v26.179c0 6.122-1.245 9.184-4.667 10.636h19.093s3.577 0 4.744-3.415l2.45-7.26c-1.79.392-3.967.431-6.456.431-9.876 0-11.86-3.179-11.86-13.344 0-9.38 1.478-13.501 11.86-13.501 9.138 0 11.471 2.354 11.9 10.361l6.065-17.818c-.039 0 1.128-3.415-2.41-3.415M167.08 23.51c0-5.613-.738-7.85-6.065-7.85-4.278 0-5.483 1.256-5.483 7.85 0 5.926-.156 9.38 5.483 9.38 6.338.039 6.065-3.14 6.065-9.38m-33.051-7.85c-5.327 0-5.677 1.138-5.677 5.809h11.51c0-5.417-.778-5.81-5.833-5.81m60.504 7.85c0-5.612-.817-7.85-6.183-7.85-4.277 0-5.482 1.257-5.482 7.85 0 5.927 0 9.38 5.482 9.38 6.183.04 6.183-3.14 6.183-9.38"
                            />
                            <path fill="#000"
                                d="M98.126 10.999v25.69h6.223V20.832c-.117-3.343.972-5.441 4.667-5.441 2.917 0 3.15 1.516 3.15 4.12v1.32H118v-2.059c0-4.858-1.322-8.084-6.806-8.084-2.995 0-5.679.816-6.884 3.653h-.234l.156-3.38h-6.106v.038zM86.186 36.65h6.223V20.56c0-8.59-3.462-9.872-11.746-9.872-5.912 0-11.084.31-11.084 7.773h6.223c0-3.342 2.178-3.575 4.861-3.575 5.173 0 5.523 1.476 5.523 5.635v3.342h-.195c-1.477-2.876-4.589-2.914-7.74-2.914-6.416 0-9.645 1.71-9.645 7.89 0 6.956 3.812 8.161 9.646 8.161 2.995 0 6.806-.117 8.05-3.537h.156l-.272 3.187zm-5.717-11.543c3.072 0 5.717.117 5.717 3.654 0 3.653-2.411 3.964-5.717 3.964-4.2 0-5.679-.35-5.679-3.964.04-3.654 2.528-3.654 5.679-3.654zM57.172 36.65h6.223V0h-6.223v36.65zM39.71 15.39c5.523 0 5.95 1.555 5.95 8.512 0 6.84-.427 8.356-5.95 8.356-5.522 0-5.95-1.515-5.95-8.356 0-6.957.428-8.511 5.95-8.511zm0-4.702c-10.812 0-12.173 3.187-12.173 13.137 0 9.91 1.361 13.097 12.173 13.097s12.174-3.187 12.174-13.097c0-9.95-1.362-13.137-12.174-13.137zm-16.801 7.307c0-7.19-6.068-7.268-11.124-7.268-6.65 0-11.784.155-11.784 7.19 0 6.568 1.711 7.812 11.24 7.967 6.262.117 6.417 1.205 6.417 3.46 0 3.536-2.178 3.42-5.873 3.42-4.589 0-5.483-.428-5.561-4.159H0c0 8.356 4.745 8.356 11.784 8.356 6.573 0 12.057-.7 12.057-7.618 0-8.317-5.406-7.89-11.551-8.161-5.406-.272-6.067-.35-6.067-2.915 0-3.381 2.256-3.381 5.561-3.381 3.306 0 4.94 0 4.94 3.07h6.184v.039z"/>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }

    renderTitle() {
        return (
            <div className="title text-4xl">{this.props.name}</div>
        )
    }

    render() {
        return (
            <div className="header">
                {this.renderTime()}
                {this.renderTitle()}
                {this.renderLogo()}
            </div>
        );
    }
}
