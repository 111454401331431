import Axios from "axios";

const SLEEP_AMOUNT = 110;

export interface AxiosResponse<T = any> {
    data: T;
    status: number;
    statusText: string;
    headers: any;
    request?: any;
}

let CacheStorage: Storage;

class TempStorage implements Storage {
    private readonly items: Map<string, string>;

    constructor() {
        this.items = new Map()
    }

    get length(): number {
        return this.items.size;
    }

    clear(): void {
        this.items.clear()
    }

    getItem(key: string): string | null {
        let v = this.items.get(key);

        return v === undefined ? null : v;
    }

    key(index: number): string | null {
        return Object.keys(this.items)[index];
    }

    removeItem(key: string): void {
        this.items.delete(key)
    }

    setItem(key: string, value: string): void {
        this.items.set(key, value)
    }
}

function tryGet(): Storage {
    let rand = 'test.' + Date.now();

    function testThing(v: 'localStorage' | 'sessionStorage') {
        window[v].setItem(rand, '1')

        if (window[v].getItem(rand) === '1') {
            window[v].removeItem(rand)
            return window[v]!;
        }
    }

    try {
        if (testThing('localStorage')) {
            return window.localStorage;
        }
    } catch (e) {
    }

    try {
        if (testThing('sessionStorage')) {
            return window.sessionStorage;
        }
    } catch (e) {
    }

    console.warn('No storage found');

    return new TempStorage()
}

function LocalstorageAvailable() {
    if (CacheStorage) {
        return;
    }

    CacheStorage = tryGet()

    //
    // if ('localStorage' in window) {
    //     try {
    //         window.localStorage.setItem('test', '1');
    //
    //         if (window.localStorage.getItem('test') === '1') {
    //             Storage = window.localStorage;
    //             console.debug('Using localStorage')
    //             return true;
    //         }
    //     } catch (e) {
    //     }
    //
    //     try {
    //         window.sessionStorage.setItem('test', '1');
    //
    //         if (window.sessionStorage.getItem('test') === '1') {
    //             console.debug('Using sessionStorage')
    //             Storage = window.sessionStorage;
    //             return true;
    //         }
    //     } catch (e) {
    //         console.error('No localstorage or sessionStorage available, using temp storage')
    //         Storage = new TempStorage();
    //     }
    // }
}

type StorageKey = {
    key: string,
    seconds?: number
}

// type Callback = (success: object | null, error: Error | null) => void;

export default class Client {
    private readonly api_key: string;
    private readonly site_id: string;
    private readonly api_url: string;
    private last_request_at: number;
    private queued: number;

    constructor(api_key: string, site_id: string, api_url: string) {
        this.api_key = api_key;
        this.site_id = site_id;
        this.api_url = api_url;
        this.last_request_at = Date.now() - 3000;
        this.queued = 0;

        LocalstorageAvailable();
    }

    private static load({key}: StorageKey) {
        const val = CacheStorage.getItem(key);

        if (val === null) {
            return null;
        }

        try {
            let item = JSON.parse(val);

            // if (typeof item['data'] !== 'undefined' && typeof item['status'] !== 'undefined') {
            //     item = item['data']
            // }

            if (item !== null && typeof item === 'object' && typeof item['body'] === 'object' && typeof item['expires'] === 'number') {
                let now = Date.now();

                if (item['expires'].toString().length === 10) {
                    now = parseInt((Date.now() / 1000).toString());
                }

                if ((now) > item['expires']) {
                    console.log(key, 'Item expired');
                    return null;
                }

                console.log('%s Item %s expires in %s minutes', 'Load Cache -', key, (((item['expires'] - now)) / 60).toFixed(2));

                // if (typeof item['body']['data'] !== 'undefined' && typeof item['body']['status'] !== 'undefined') {
                //     item['body'] = item['body']['data']['body']
                // }

                // if (typeof item['body']['body'] !== 'undefined') {
                //     item['body'] = item['body']['body']
                // }

                return item['body'];
            }
        } catch (error) {
            console.error('bad load cache', key, error)
        }

        return null;
    }

    private static put({key, seconds}: StorageKey, value: object) {
        let val = JSON.stringify({body: value, expires: parseInt((Date.now() / 1000).toString()) + ((seconds ?? 1800))});

        try {
            CacheStorage.setItem(key, val);
        } catch (error) {
            return Promise.reject(error)
        }

        return Promise.resolve(value)
    }

    private static readResponse(storageKey: StorageKey, response: AxiosResponse) {
        try {
            let data = response.data;

            let store_for = data?.store_for ?? 0;

            if (store_for > 0 && !isNaN(store_for)) {
                console.log('Storing for', store_for, 'instead of', storageKey.seconds)
                storageKey.seconds = store_for;
            }
        } catch (e) {

        }

        let body = JSON.parse(JSON.stringify(response.data['body']));

        return Client.put(storageKey, body);
    }

    // // noinspection JSUnusedGlobalSymbols
    // fetch(key: string, cb: Callback): void {
    //     this.fetchAsync(key)
    //     .then(function (value) {
    //         cb(value, null)
    //     }).catch(function (error) {
    //         cb(null, error)
    //     })
    // }

    async fetchAsync(key: string) {
        let storeKey: StorageKey = {
            key: this.formatKey(key),
            seconds: 1800
        };

        let cached = Client.load(storeKey);

        if (cached !== null) {
            return Promise.resolve(cached);
        }

        if (++this.queued > 1) {
            let queued = this.queued;

            console.log('in queue:', queued, ' | waiting', queued * SLEEP_AMOUNT);

            await new Promise((r) => {
                setTimeout(r, queued * SLEEP_AMOUNT)
            })
        }

        // if ((Date.now() - this.last_request_at) < 12000 ) {
        //     console.log('sleeping for a bit');
        //
        //     this.last_request_at = Date.now()
        //
        //     await new Promise((r) => {
        //         setTimeout(r, 500)
        //     })
        // }

        console.info('making request:', key)

        let response;

        try {
            response = await Axios.get(this.api_url, this.getOptions(key))
        } finally {
            --this.queued;
        }

        return await Client.readResponse(storeKey, response)
    }

    private formatKey(key: string): string {
        return `${this.site_id}_${key}`
    }

    private getOptions(key: string) {
        return {
            params: {
                type: key,
                api_key: this.api_key,
                site_id: this.site_id
            }
        };
    }
}
