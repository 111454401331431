import React from 'react';
// import '../css/overview.scss';
import Client from "../utils/Client";
import {numToPower} from "../utils/ValuesConverter";
import dayjs from "dayjs";
import 'dayjs/locale/nn'
import {Locale} from '../Locale/no-NO'

interface OverviewState {
    envBenefits?: {
        gasEmissionSaved?: {
            units: string
            co2?: number
            so2?: number
            nox?: number
        },
        treesPlanted?: number
        lightBulbs?: number
    }

    overview?: {
        lastUpdateTime?: { energy: number }
        lifeTimeData?: { energy: number }
        lastYearData?: { energy: number }
        lastMonthData?: { energy: number }
        lastDayData?: { energy: number }
        currentPower?: { power: number }
    }
}

export default class Overview extends React.Component<{ client: Client }, OverviewState> {
    state: OverviewState = {}

    async testGet(client: Client): Promise<OverviewState> {
        let envBenefits, overview;
        try {
            [envBenefits, overview] = await Promise.all([
                client.fetchAsync('envBenefits'),
                client.fetchAsync('overview')
            ])
        } catch (e) {
            console.error('Couldn\'t load envBenefits', e)
        }

        // try {
        //     overview = await client.fetchAsync('overview');
        // } catch (e) {
        //     console.error('Couldn\'t load overview', e)
        // }

        return {
            envBenefits: envBenefits?.envBenefits,
            overview: overview?.overview
        }
    }

    componentDidMount() {
        this.testGet(this.props.client).then(({envBenefits, overview}) => {
            this.setState((state) => ({
                ...state,
                envBenefits,
                overview
            }))
        })
        // client
        // .fetchAsync('envBenefits')
        // .then(result => {
        //     return client.fetchAsync('overview')
        // })
        // .catch(error => console.error(error))
        // .finally(s => {
        //     console.log(s)
        // })
        //
        // Promise.all([
        //     client.fetchAsync('envBenefits'),
        //     client.fetchAsync('overview')
        // ]).then((result: any[]) => {
        //     let obj = Object.assign([...result])
        //     console.log(obj)
        //     this.setState({
        //         envBenefits: result.find?.(e => 'envBenefits' in e)
        //     })
        // }).catch(e => {
        //     console.error(e)
        // })

        // client.fetchAsync('overview').then((result) => {
        //     return {overview: (result ?? {overview: {}}).overview}
        //     //this.setState({...this.state, overview: (result ?? {overview: {}}).overview});
        // })
        // .then()
        //
        // .catch((error) => {
        //     console.error('overview', error)
        // })

        // client.fetchAsync('envBenefits').then((result) => {
        //     this.setState({...this.state, envBenefits: (result ?? {envBenefits: {}}).envBenefits});
        // }).catch((error) => {
        //     console.error('envBenefits', error)
        // })
    }

    convert(amount?: number, isEnergy: boolean = true) {
        if (amount === undefined || amount === null) {
            return (
                <div className={"overview-nm"} style={{color: 'rgba(0, 0, 0, 0.4)'}}><span>Laster inn </span></div>
            )
        }

        let [i, unit] = numToPower({data: Number(amount ?? 0), minRound: 2, isEnergy})

        return (
            <div className={"overview-nm"}>{i}<span>{unit}</span></div>
        )
    }

    renderBox(title: string, value?: number) {
        return (
            <div className={"overview-box"}>
                <div className={"overview-title"}>{title}</div>
                {this.convert(value)}
            </div>
        )
    }

    render() {
        return (
            <div className={"overview"}>
                {this.renderBox(Locale.Today, this.state.overview?.lastDayData?.energy)}
                {this.renderBox(dayjs().locale('nn').format('MMMM').replace(/^./, (s) => s.toUpperCase()), this.state.overview?.lastMonthData?.energy)}
                {this.renderBox(Locale.Lifetime, this.state.overview?.lifeTimeData?.energy)}
                <div className={"overview-box overview-env"}>
                    {this.renderCo2()}
                    {this.renderTrees()}
                </div>
            </div>
        )
    }

    renderCo2() {
        let co2: string = '';
        let units: string = this.state.envBenefits?.gasEmissionSaved?.units ?? 'kg';

        if (this.state.envBenefits?.gasEmissionSaved?.co2 !== undefined) {
            let num = Number(this.state.envBenefits?.gasEmissionSaved?.co2 ?? 0);

            try {
                let formatter = new Intl.NumberFormat('nn-NO', {maximumFractionDigits: 2});

                co2 = formatter.format(num)
            } catch (_e) {
                co2 = num.toFixed(2)
            }
        }

        return (
            <div className={"overview-env-row"}>
                <svg xmlns="http://www.w3.org/2000/svg" width={"60px"} height={"60px"} viewBox="0 0 60 60">
                    <g fill="none" fillRule="evenodd">
                        <path fill="#57A4FF" d="M44 10a8 8 0 00-3 .6 11 11 0 00-22 0A8 8 0 1016 26h28a8 8 0 000-16z"/>
                        <path fill="#2488FF" d="M10 18c0-4 3-7.4 7-8a8 8 0 10-1 16h2a8 8 0 01-8-8z"/>
                        <path fill="#5EAC24" d="M8.3 39.7l-3.9 1L24 52l-1-3.9a12 12 0 00-14.7-8.4z"/>
                        <path fill="#4E901E" d="M5.4 44.5A12 12 0 0020.1 53l3.9-1L4.4 40.7l1 3.8z"/>
                        <path fill="#91DC5A" d="M51.7 39.7A12 12 0 0037 48L36 52l19.6-11.3-3.9-1z"/>
                        <path fill="#7ED63E" d="M54.6 44.5l1-3.8L36 52l3.9 1a12 12 0 0014.7-8.5z"/>
                        <path fill="#CCFC5C" d="M27.2 22.2a12 12 0 000 17L30 42V19.4l-2.8 2.8z"/>
                        <path fill="#D7FC7E" d="M32.8 22.2L30 19.4V42l2.8-2.8a12 12 0 000-17z"/>
                        <path fill="#000"
                            d="M55.9 39.7l-4-1a13 13 0 00-15.8 9.2l-1 3.5-.1.1a20 20 0 00-4 4v-13l2.5-2.6c5.1-5.1 5.1-13.3 0-18.4l-2.8-2.8a1 1 0 00-1.4 0l-2.8 2.8c-5.1 5-5.1 13.3 0 18.4l2.5 2.5v13.1a20 20 0 00-4-4H25l-1-3.6c-1.8-7-9-11-15.9-9.2l-3.9 1a1 1 0 00-.7 1.2l1 4a13 13 0 0016 9.1l3.4-1v.1a18 18 0 015.3 6.3 1 1 0 001.4.5l.5-.7a18 18 0 015.2-6V53l3.4.9a13 13 0 0016-9.2l1-3.9a1 1 0 00-.7-1.2zm-47.4 1A11 11 0 0122 48.3l.4 1.5L7 41l1.5-.4zM20 52a11 11 0 01-13.5-7.8L6 42.8l15.4 8.9-1.5.4zm15.4-21.4c0 3-1.1 5.7-3.2 7.8l-1.1 1V21.9l1.1 1.1c2 2 3.3 4.9 3.2 7.8zM28 38.5a11 11 0 010-15.6l1.1-1.1v17.8l-1.1-1.1zm23.6 2.1l1.5.4-15.4 9 .4-1.6a11 11 0 0113.5-7.8zm-3 10.4a11 11 0 01-8.4 1l-1.5-.3 15.4-9-.4 1.6a11 11 0 01-5.1 6.7zM5 34v-2h2a1 1 0 000-2H5v-2a1 1 0 00-2 0v2H1a1 1 0 000 2h2v2a1 1 0 002 0zm54-4h-2v-2a1 1 0 00-2 0v2h-2a1 1 0 000 2h2v2a1 1 0 002 0v-2h2a1 1 0 000-2zm-16-8c0-.6-.4-1-1-1h-5a1 1 0 000 2h5c.6 0 1-.4 1-1zm4 1a1 1 0 000-2h-1a1 1 0 000 2h1z"/>
                        <path fill="#000" d="M44 27h-4a1 1 0 010-2h4a7 7 0 10-2.7-13.5 1 1 0 01-1.4-.8 10 10 0 00-19.8 0 1 1 0 01-1.4.8A7 7 0 1016 25h4a1 1 0 010 2h-4a9 9 0 112.3-17.7 12 12 0 0123.4 0A9 9 0 1144 27z"/>
                    </g>
                </svg>
                <div>
                    <p>Spart CO2 utslipp</p>
                    <span style={{color: co2 ? '' : 'white'}}>{co2} {units}</span>
                </div>
            </div>
        )
    }

    renderTrees() {
        let trees = this.state.envBenefits?.treesPlanted;

        return (
            <div className={"overview-env-row"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 51" width={"60px"} height={"60px"}>
                    <g fill="none" fillRule="evenodd">
                        <path fill="#91DC5A" d="M23 12.1V0l-1 .5c-4.8 2.6-7.6 7-7.6 11.6 0 4.3 2.4 8.3 6.5 11V12h2z"/>
                        <path fill="#6DC82A" d="M25 12.1v11c4.2-2.7 6.6-6.7 6.6-11 0-4.7-2.9-9-7.6-11.6L23 0v12.1h2z"/>
                        <path fill="#91DC5A" d="M36.2 24.7l8.9-5.3-1-.6c-2-1.2-4.3-1.9-6.5-1.9-1.8 0-3.5.5-5 1.4a11 11 0 00-5.2 9.2l7.7-4.6 1.1 1.8z"/>
                        <path fill="#6DC82A"
                            d="M37.3 26.5L29.6 31c1.7.8 3.5 1.2 5.3 1.2 1.9 0 3.6-.4 5.1-1.3 3.4-2 5.3-6 5.1-10.5v-1l-8.9 5.2 1 1.8zM9.8 24.7l-9-5.3v1.1C.7 25.1 2.6 29 6 31c1.6.9 3.3 1.3 5.1 1.3 1.8 0 3.6-.4 5.4-1.2l-7.7-4.6 1-1.8z"/>
                        <path fill="#91DC5A" d="M10.8 23l7.7 4.5c-.2-4-2-7.4-5-9.2A9.8 9.8 0 008.2 17c-2.1 0-4.4.7-6.5 2l-1 .5 9 5.3 1-1.8z"/>
                        <path fill="#00665A" d="M25 36.2v-2.5l4.6-2.6 7.7-4.6-1.1-1.8-1-1.8-7.8 4.6-2.4 1.4V12.1H21v16.8l-2.4-1.4-7.7-4.6-1 1.8-1.1 1.8 7.7 4.6 4.5 2.6v2.5a14.7 14.7 0 014.1 0z"/>
                        <path fill="#B39A7C" d="M23 36a14.9 14.9 0 00-10.1 4A11.1 11.1 0 000 51H23V36z"/>
                        <path fill="#A78966" d="M34.8 39.8c-.6 0-1.1 0-1.7.2A15 15 0 0023 36v15h23c0-6.2-5-11.2-11.2-11.2z"/>
                    </g>
                </svg>
                <div>
                    <p>Tilsvarende antall trær planet</p>
                    <span style={{color: trees ? '' : 'white'}}>{trees?.toFixed?.(2) ?? '.'}</span>
                </div>
            </div>
        )
    }
}
