import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {fixEndValue, numToPower} from "../utils/ValuesConverter";
import Client from "../utils/Client";
import {Locale} from "../Locale/no-NO";

function renderTodays(response: { power?: any; energy?: any; }): Highcharts.Options {
    let data: { values: { date: string, value?: number | null }[] };

    if (response.power ?? null) {
        data = response.power;
    } else {
        throw new Error('Bad data' + JSON.stringify(response))
    }

    let values = data!.values;

    if (typeof values[values.length - 1]["value"] !== "number") {
        values = fixEndValue(values);
    }

    let last = values[values.length - 1];

    let today_date = new Date(last.date);

    today_date.setUTCHours(3, 0, 0, 0);

    let vals: { x: number, y: number }[] = fixEndValue(values.filter(function (a) {
        return (new Date(a.date)).getUTCDate() === today_date.getUTCDate(); //&& typeof a['value'] === 'number'
    })).map(function (a) {
        return {x: +new Date(a.date), y: Number(Number(a.value).toFixed(2))};
    });

    let slice = vals.findIndex((value => value.y > 0))

    if (slice > 0) {
        vals = vals.slice(slice)
        console.log('sliced', slice)
    }
    //
    // let vals2 = new Map();
    //
    // for (let {x, y} of vals) {
    //     let d = (new Date(x));
    //
    //     let key = d.getHours()+'';
    //
    //     if (d.getMinutes() >= 30) {
    //         key += '30';
    //     } else {
    //         key += '00'
    //     }
    //
    //     if (vals2.has(key)) {
    //         let old = vals2.get(key);
    //
    //
    //         vals2.set(key, {x: old.x, y: old.y+y})
    //     } else {
    //         vals2.set(key, {x, y})
    //     }
    // }
    //
    // vals = Array.from(vals2.values())

    return {
        chart: {},
        loading: {},
        data: {},
        title: {
            text: Locale.PowerToday,
        },
        plotOptions: {
            series: {
                animation: {
                    duration: 700,
                    easing: 'ease'
                },
            },
        },
        series: [
            {
                type: "line",
                name: "",
                data: vals,
            },
        ],
        yAxis: {
            title: {
                text: "",
            },
            labels: {
                formatter() {
                    //return this.value.toString();
                    return numToPower({data: this.value, minRound: 2, isEnergy: false}).join(' ');
                },
            },
        },
        tooltip: {
            enabled: true,
            shared: false,
        },
        legend: {
            enabled: false,
        },
        time: {
            timezone: 'Europe/Oslo'
        },
        xAxis: {
            title: {},
            labels: {},
            tickInterval: 1800*1000,
            //showLastLabel: true,
            type: "datetime",
        },
    }
}

export default class extends React.Component<{ client: Client }, { power?: [] }> {
    componentDidMount() {
        this.props.client.fetchAsync('power').then((result) => {
            this.setState(() => ({
                power: result.power
            }))
        })
    }

    render() {
        // const [chartOptions, setChartOptions] = React.useState({
        //     xAxis: {
        //         categories: ['A', 'B', 'C'],
        //     },
        //     series: [
        //         {data: [1, 2, 3]}
        //     ],
        //     title: {
        //         text: "Daily"
        //     },
        //     legend: {
        //         enabled: false,
        //     },
        //     subtitle: {
        //         text: ""
        //     },
        //     plotOptions: {
        //         line: {
        //             animation: false
        //         }
        //     },
        // });
        //
        // const updateSeries = () => {
        //     setChartOptions({
        //         ...chartOptions, series: [
        //             {data: [Math.random() * 5, 2, 1]}
        //         ]
        //     })
        // }

        if (!(this.state?.power ?? null)) {
            return (<div>

            </div>);
        }

        let options;

        try {
            options = renderTodays({power: this.state.power});
        } catch (e) {
            return (<div className={"chart-holder"}>
                Error {(e as Error).message}
            </div>);
        }

        return (
            <div className={"chart-holder"}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        )
    }
}
