import React from 'react';
import {LoadIcon, GridIcon, SolarIcon} from '../svg/LoadIcon'
import Client from '../utils/Client';
import CurrentPowerFlow, {Flow, PowerFlowStructure} from "../utils/Converters/CurrentPowerFlow";
// import '../css/flow.scss';

class Triangle extends React.Component<{ Color: string, Direction: 'Right' | 'Left', Hidden: boolean }, any> {
    render() {
        return (
            <div className={
                "Triangle Triangle-" + this.props.Color +
                " Triangle-" + this.props.Direction +
                (this.props.Hidden ? ' Triangle-Hidden' : '')
            }>
            </div>
        );
    }
}

// interface CurrentFlow {
//     grid: PowerValue,
//     load: PowerValue,
//     pv?: PowerValue,
//     grid_load?: boolean,
//     load_grid?: boolean,
//     pv_load?: boolean,
//     load_pv?: boolean
// }

interface PowerState {
    flow?: PowerFlowStructure,
    isLoaded: boolean,
    error?: Error | null
}

class PowerFlow extends React.Component<{ client: Client }, PowerState> {
    state: PowerState = {
        isLoaded: false,
        flow: {
            connections: [],
            importing: false,
            exporting: false,
            pv: {
                status: 'Laster',
                unit: ' ',
                value: null
            },
            grid: {
                status: "Laster",
                unit: ' ',
                value: null
            },
            load: {
                status: "Laster",
                unit: ' ',
                value: null
            }
        }
    };

    componentDidMount() {
        this.props.client.fetchAsync('currentPowerFlow')
        .then(
            (result) => {
                let flow = CurrentPowerFlow(result);
                this.setState({
                    flow: flow!,
                    isLoaded: true
                });
            }, (error) => {
                this.setState({
                    flow: undefined,
                    isLoaded: true,
                    error
                });
            })

    }

    renderPower(flow?: Flow) {
        return (
            <div className="kW">{flow?.value}<span>{flow?.unit}</span></div>
        )
    }

    // getConnection(connections: Connection[]):  Connection | undefined{
    //     return connections.find((a => a.from === 'PV' || a.to === 'PV'))
    // }

    render() {
        let pv: Flow | undefined = this.state?.flow?.pv ?? undefined;
        let load: Flow | undefined = this.state?.flow?.load ?? undefined;
        let grid: Flow | undefined = this.state?.flow?.grid ?? undefined;

        // let connections: Connection[] = this.state.flow?.connections ?? [];
        // let pvConnection: Connection | undefined = connections.find((a => a.from === 'PV' || a.to === 'PV'));
        // let gridConnection: Connection | undefined = connections.find((a => a.from === 'PV' || a.to === 'PV'));

        return (
            <div className="flows">
                <div className="flow-panel flow-panel--pv">
                    <div className="flow-left">
                        <SolarIcon/>
                        <div className="flow-icon-text">{pv?.status ?? 'Loading'}</div>
                    </div>
                    <div className="flow-right">
                        <div className="flow-title">Fra solpanel</div>
                        {this.renderPower(pv)}
                    </div>
                </div>

                <Triangle Color="Green" Direction="Right" Hidden={false}/>

                <div className="flow-panel flow-panel--load">
                    <div className="flow-left">
                        <LoadIcon/>
                        <div className="flow-icon-text">{load?.status ?? 'Loading'}</div>
                    </div>
                    <div className="flow-right">
                        <div className="flow-title">Strømforbruk</div>
                        {this.renderPower(load)}
                    </div>
                </div>

                <Triangle Color="Red" Direction={this.state.flow?.exporting === true ? 'Right' : 'Left'} Hidden={false}/>

                <div className="flow-panel flow-panel--grid">
                    <div className="flow-right">
                        <div className="flow-title">Fra nett</div>
                        {this.renderPower(grid)}
                    </div>
                    <div className="flow-left">
                        <GridIcon/>
                        <div className="flow-icon-text">{grid?.status ?? 'Loading'}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PowerFlow;
