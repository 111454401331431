export interface CurrentPowerFlow {
    siteCurrentPowerFlow?: SiteCurrentPowerFlow;
}

export interface SiteCurrentPowerFlow {
    unit: string;
    connections: Connection[];
    GRID?: Grid;
    LOAD?: Grid;
    PV?: Grid;
}

export interface Connection {
    from: string;
    to: string;
}

type Grid = {
    status: string;
    currentPower: number;
}

export interface Flow {
    unit: string,
    value: number | null,
    status: string
}

export interface PowerFlowStructure {
    pv: Flow | null,
    grid: Flow | null,
    load: Flow | null,
    importing: boolean,
    exporting: boolean,
    connections: Connection[]
}

export default function resolve(response?: CurrentPowerFlow): PowerFlowStructure | null {
    if (response?.siteCurrentPowerFlow?.unit === null) {
        return null;
    }

    let {GRID, LOAD, PV, unit} = response!.siteCurrentPowerFlow!;

    let connections = (response?.siteCurrentPowerFlow?.connections ?? []).map(connection => ({
        from: connection?.from?.toLowerCase?.() ?? '',
        to: connection?.to?.toLowerCase?.() ?? ''
    }))

    let exporting: boolean = !!connections.find(e => e.from === 'load' && e.to === 'grid')
    let importing: boolean = !!connections.find(e => e.from === 'grid' && e.to === 'load')

    function convert(obj: Grid | undefined, unit: string): null | Flow {
        if (!obj) {
            return null;
        }

        let {currentPower: value, status} = obj;

        status = status.replace(/active/i, 'Aktiv').replace(/idle/i, 'Inaktiv')

        return {
            value,
            status,
            unit
        }
    }

    return {
        connections,
        exporting,
        importing,
        pv: convert(PV, unit),
        grid: convert(GRID, unit),
        load: convert(LOAD, unit)
    }
}
