import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './css/root.scss';
import Client from "./utils/Client";
import PowerFlow from "./Components/PowerFlow";
import TitleHeader from "./Components/TitleHeader";
import Overview from "./Components/Overview";
import TodayChart from './Components/TodaysChart'
import DailyChart from "./Components/DailyChart";
import Footer from "./Components/Footer";
import WindMillLoading from "./Components/Loading";

interface AppState {
    client?: Client,
    name?: string,
    loaded: boolean,
}

class App extends React.Component<{}, AppState> {
    state: AppState = {
        loaded: false
    };

    constructor(props: {}) {
        super(props);

        this.state = {
            client: new Client("XQP4D69X8Y9M3ADEOXRBGZISQB4QEONC", "1332490", 'https://dev.pnwp.no/solar/fetch.php'),
            name: "Parkhuset Sameiet 5667",
            loaded: false
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState((s) => ({
            ...s,
            loaded: true
        })), 600)
    }

    render() {
        if (!this.state.loaded || !this.state.client) {
            return (<WindMillLoading/>)
        }

        let classes = [
            'app',
        ]

        !window.location.hash.includes('no-font') && classes.push('open-sans')

        return (<div className={classes.join(' ')}>
            <TitleHeader name={this.state.name ?? ''}/>
            <PowerFlow client={this.state.client}/>
            <Overview client={this.state.client}/>
            <div className={"chart-columns"}>
                <TodayChart client={this.state.client}/>
                <DailyChart client={this.state.client}/>
            </div>
            <Footer footerText={"Infoskjermløsning fra"} url={"https://displaysystem.no"} urlText={"DisplaySystem.no"}/>
        </div>);
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
