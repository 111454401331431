type Value = ({ date: string, value?: number | null });
type XY = { x: Date, y: number };

export function fixEndValue(values: Value[]): Value[] {
    let idx = values.length - 1;

    while (idx >= 0 && typeof values[idx]['value'] !== 'number') {
        idx -= 1;
    }

    return values.slice(0, idx + 1)
}

export function toData(values: XY[]): any[] {
    let series: {name: string, data: {x: number, y: number}[]}[] = [];

    let data: Map<string, XY[]>;

    values = values.slice(values.findIndex(s => +s.y > 0))

    data = values.reduce(function (a: Map<string, XY[]>, b: XY) {
        let key = "" + ((b['x'].getMonth() * 30) + (b['x'].getDate())) as string;

        if (!a.has(key)) {
            a.set(key, [])
        }

        a!.get(key)!.push(b)

        return a;
    }, new Map());

    let groups = Array.from(data.values()).slice(Math.max(0, data.size - 4)) as [{ x: Date, y: number }[]];

    for (const group of groups) {
        let d = Array.from(group.map((v: { x: Date, y: number }) => {
            let h = v['x'].getHours();
            let m = v['x'].getMinutes();

            // if (h < 10) {
            //     h = '0' + h
            // }

            // if (m < 10) {
            //     m = '0' + m
            // }

            return {x: h, m, h, y: v['y']}
        })); // .filter(s => s.h > 5 && s.h < 20)

        let mmap = new Map()

        for (let _i = 0; _i < 24; _i++) {
            mmap.set(_i, {x: _i, y: 0})
        }

        for (const vi of d) {
            const {h, y} = vi as { h: number, y: number };

            if (isNaN(+y)) {
                continue;
            }

            mmap.get(h).y += y
        }

        series.push({
            name: (group.find(() => true) || {x: new Date()}).x.toDateString(),
            data: Array.from(mmap.values()).slice(6, 20)
        })
    }

    return series
}
//
// function round(n: number, precision: number) {
//     const factor = Math.pow(10, precision);
//     const tempNumber = n * factor;
//     const roundedTempNumber = Math.round(tempNumber);
//     return roundedTempNumber / factor;
// }
//
// function convertNum(data: number, type: string, r: number = 0, d: number = 0): [number, string] {
//     return [round(d > 0 ? data / d : data, r), type]
// }
//
// export function numToPower(data: number, minRound: number): [number, string] {
//     if (data <= 1000) {
//         return convertNum(data, "Wh", minRound);
//     } else if (data > 1000000) {
//         return convertNum(data, 'MWh', minRound ? minRound : 2, 1000000)
//     }
//
//     return convertNum(data, 'kWh', minRound ? minRound : 1, 1000)
// }

function round(n: number, precision: number) {
    const factor = Math.pow(10, precision);
    const tempNumber = n * factor;
    const roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
}

function convertNum(data: number, type: string, r: number, d: number) {
    return [round(d > 0 ? data / d : data, r), type]
}

interface NumToPowerParams {
    data: number;
    minRound: number;
    isEnergy?: boolean;
}

export function numToPower({data, minRound, isEnergy = false}: NumToPowerParams) {
    let h = isEnergy ? 'h' : '';

    if (data < 1000) {
        return convertNum(data, "W" + h, minRound, 0);
    } else if (data > 1000000) {
        return convertNum(data, 'MW' + h, minRound ? minRound : 2, 1000000)
    }

    return convertNum(data, 'kW' + h, minRound ? minRound : 1, 1000)
}
