import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {fixEndValue, numToPower, toData} from "../utils/ValuesConverter";
import Client from "../utils/Client";
import {Locale} from "../Locale/no-NO";

function renderDailyChart(response: { power?: any; energy?: any; }): Highcharts.Options {
    let values;

    if (response.power ?? null) {
        values = response.power;
    } else if ('energy' in response) {
        values = response.energy;
    } else {
        values = response
    }

    values = values.values;

    if (typeof values[values.length - 1]["value"] !== "number") {
        values = fixEndValue(values);
    }

    let result2 = values.map((a: { date: Date | number, value?: number }) => ({x: new Date(a["date"]), y: Math.round(Number(a.value))}));


    return {
        pane: {
            size: "50%"
        },
        chart: {
            type: "column",
        },
        title: {
            text: Locale.EnergyDaily,
        },
        plotOptions: {
            series: {
                animation: {
                    duration: 1051
                },
            },
        },
        time: {
            timezone: 'Europe/Oslo'
        },
        series: toData(result2),
        yAxis: {
            title: {
                text: "",
            },
            // text: " ",
            labels: {
                formatter: function () {
                    return numToPower({data: this.value + 0.0001, minRound: 2, isEnergy: true}).join(' ');
                },
            },
        },
        tooltip: {
            // formatter: function () {
            //     return numToPower({data: this.y + 0.0001, minRound: 2, isEnergy: true}).join(' ');
            // },
        },
        xAxis: {
            type: "category",
            categories: (new Array(24)).fill(0).map((_e, a) => (a).toString().padStart(2,'0')+':00'),
            // labels: {
            //     formatter: function () {
            //         return (this.value < 10 ? '0' + this.value : this.value) + ':00';
            //     },
            // },
        },
    }
}

export default class extends React.Component<{ client: Client }, {power?: []}> {
    componentDidMount() {
        this.props.client.fetchAsync('energy').then((result) => {
            this.setState({
                power: result.energy
            })
        })

    }

    render() {
        // const [chartOptions, setChartOptions] = React.useState({
        //     xAxis: {
        //         categories: ['A', 'B', 'C'],
        //     },
        //     series: [
        //         {data: [1, 2, 3]}
        //     ],
        //     title: {
        //         text: "Daily"
        //     },
        //     legend: {
        //         enabled: false,
        //     },
        //     subtitle: {
        //         text: ""
        //     },
        //     plotOptions: {
        //         line: {
        //             animation: false
        //         }
        //     },
        // });
        //
        // const updateSeries = () => {
        //     setChartOptions({
        //         ...chartOptions, series: [
        //             {data: [Math.random() * 5, 2, 1]}
        //         ]
        //     })
        // }

        if ( ! this.state?.power??null )
        {
            return (
                <div>

                </div>
            )
        }

        let options = renderDailyChart({power: this.state.power});

        return (
            <div className={"chart-holder"}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        )
    }
}
